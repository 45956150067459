import { LINKS } from '@utils/constants';
import { IPartner } from '@utils/types';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image';
import * as React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { useTranslation } from 'react-i18next';

export default function PartnersTemplate({
	data,
	pageContext: {
		breadcrumb: { location, crumbs },
	},
}: {
	data: any;
	pageContext: {
		breadcrumb: {
			location: string;
			crumbs: Breadcrumb[]
		};
	};
}) {
	const { t } = useTranslation();

	const { markdownRemark, banner } = data;
	if (!markdownRemark) return null;

	const { frontmatter, html } = markdownRemark;

	const cover = getImage(frontmatter.image.childImageSharp.gatsbyImageData);
	const self = data.siteUrl + location;

	return (
		<Page>
			<SEO
				title={`${frontmatter.title} | ${t("_Partners")} | Realis Simulation`}
				description=""
			/>

			<Banner
				title={frontmatter.title}
				image={{
					src: banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection layout="wide">
				<div className="event-wrapper">
					<div className="col xl3 offset-xl1 m4 offset-m0 s12 s-spacer event__aside">
						{cover && (
							<div className="resource__cover">
								<GatsbyImage
									image={cover}
									alt={frontmatter.title}
								/>
							</div>
						)}
						<div className="event-button">
							<Button to={LINKS.ABOUT_PARTNERS} type="more">
								{t("_OtherPartners")}
							</Button>
						</div>
					</div>
					<div className="col xl6 offset-xl1 l7 offset-l0 m8 s12 s-spacer">
						<div className="event__content"
							dangerouslySetInnerHTML={{ __html: html }}
						/>
						<aside className="share__icons">
							<span>{t("_ShareThisPartner")}</span>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://www.linkedin.com/shareArticle?mini=true&url=${self}&partner=${frontmatter.title}`}
								className="social-icon__item social-icon--linkedin"></a>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://twitter.com/share?url=${self}&partner=${frontmatter.title}`}
								className="social-icon__item social-icon--twitter"></a>
							<a
								target="_blank"
								rel="noreferrer"
								href={`https://www.facebook.com/sharer/sharer.php?u=${self}`}
								className="social-icon__item social-icon--facebook"></a>
						</aside>
					</div>
				</div>
			</PageSection>
		</Page>
	);
}

export const pageQuery = graphql`
	query ($language: String!, $slug: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		locales: allLocale(
			filter: { ns: { in: ["_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		markdownRemark(
      		fields: { language: { eq: $language }, slug: { eq: $slug } }
		) {
			frontmatter {
				title
				summary
				date(formatString: "DD MMMM YYYY")
				image {
					...imageStandard
				}
			}
			html
			id
		}
		banner: file(relativePath: { eq: "content/about-us/technical-partners/Realis-Simulation_TechnicalPartners.jpg" }) {
			...imageBreaker
		}
	}
`;
